import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import logo from "../../assets/images/logo.svg";
import lock from "../../assets/images/lock.svg";
import cartImg from "../../assets/images/cart.svg";
import arrowDown from "../../assets/images/arrow-down.svg";
import featureImage from "../../assets/images/image.jpg";
import noPage from "../../assets/images/404page3.svg";
import client from "../../assets/images/client.jpg";
import { Shipping } from "./Shipping/Shipping";
import { Billing } from "./Billing/Billing";
import { Payment } from "./Payment/Payment";
import { Summary } from "./Summary/Summary";
import { Disclaimer } from "./Disclaimer";
import { styled } from "../../App";
import * as Api from "../../Api";
import _, { isEmpty } from "underscore";
import CurrencyFormat from 'react-currency-format';
import "./Checkout.css";
// import {Discount} from "./Discount/Discount";
// import PaytmChecksum from 'paytmchecksum';
import axios from "axios";
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import { Alert, Modal } from "react-bootstrap";
import { load } from '@cashfreepayments/cashfree-js';
import { useMediaQuery } from "@uidotdev/usehooks";

function Checkout() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const location = useLocation();
    const elements = useElements();
    const stripe = useStripe();
    const searchParams = new URLSearchParams(window.location.search)
    const addressInit = (type) => {
        return (
            {
                first_name: type?.first_name,
                last_name: type?.last_name,
                address1: type?.address1,
                address2: type?.address2,
                city: type?.city,
                company: type?.company,
                country: "India",
                phone: type?.phone,
                province: type?.province,
                zip_code: type?.zip_code,
            }
        )
    }

    const cashfree = Cashfree({
        mode: "sandbox" //or production
    });
    console.log("cashfree: ", cashfree)

    const [showStep2, setShowStep2] = useState(false)
    const [showError, setshowError] = useState(false)
    const [loading, setloading] = useState(true)
    const [showDiscount, setshowDiscount] = useState(false)

    const [activegateway, setactivegateway] = useState({})
    const [address, setAddress] = useState({
        type: localStorage.getItem("cart_type") || searchParams.get("type"),
        email: JSON.parse(localStorage.getItem("address"))?.email || "",
        shipping_address: addressInit(JSON.parse(localStorage.getItem("address"))?.shipping_address || ""),
        billing_address: addressInit(JSON.parse(localStorage.getItem("address"))?.billing_address || ""),
    })

    const [stripeData, setstripeData] = useState({
        type: localStorage.getItem("cart_type") || searchParams.get("type"),
    })
    const [error, setError] = useState({})
    const [sameAddress, setSameAddress] = useState(true)
    const [showSummary, setshowSummary] = useState(true)
    const [showGift, setshowGift] = useState(true)
    const [btnLoading, setbtnLoading] = useState(false)
    const [paymentOverlay, setPaymentOverlay] = useState(false)
    const [isGiftCard, setIsGiftCard] = useState(false)

    const hanldeError = (name, value, prefix) => {
        if (value === "") {
            setError({ ...error, [`${prefix}${name}`]: true })
        } else {
            setError({ ...error, [`${prefix}${name}`]: false })
        }
    }

    const handlePaymentChange = (e, data) => {
        setactivegateway(data)
    }
    const [disableStripe, setDisableStripe] = useState(true)
    const [cardData, setcardData] = useState({})
    const [cardElementData, setcardElementData] = useState([
        { elementType: 'cardNumber', complete: false },
        { elementType: 'cardExpiry', complete: false },
        { elementType: 'cardCvc', complete: false },
    ])
    const handleCardChange = (data) => {
        console.log("data change", data)
        let arr = []
        let valArray = _.where(cardElementData, { elementType: data?.elementType })
        if (valArray) {
            valArray = data
        }
        if (data?.elementType == "cardNumber") {
            setcardData(valArray)
            if (data?.complete) {
                elements.getElement(CardExpiryElement).focus()
            }
        }
        if (data?.elementType == "cardExpiry") {
            if (data?.complete) {
                elements.getElement(CardCvcElement).focus()
            }
        }
        console.log("valArray", valArray)
        cardElementData.map((opt) => {
            if (opt?.elementType == data?.elementType) {
                arr.push(valArray)
            } else {
                arr.push({ ...opt })
            }
        })

        setcardElementData(arr)
        let complete = arr.filter((x) => !x?.complete)
        if (complete && complete.length > 0) {
            setDisableStripe(true)
        } else {
            setDisableStripe(false)
        }
    }
    console.log("activegateway: ", activegateway)
    console.log("cardElementData: ", cardElementData)


    const cartDataFn = (data, res) => {
        let cartData = {}
        if (data?.type == "draft_order") {
            cartData = res.data.data?.draftOrder
            cartData["cart_items"] = res.data.data?.draftOrder?.line_items
            let shipping = cartData["shipping_address"]
            shipping["zip_code"] = cartData["shipping_address"]["zip"]
            delete shipping["id"]
            delete shipping["created_at"]
            delete shipping["updated_at"]
            delete shipping["province_code"]
            let billing = cartData["billing_address"]
            billing["zip_code"] = cartData["billing_address"]["zip"]
            delete billing["id"]
            delete billing["created_at"]
            delete billing["updated_at"]
            delete billing["province_code"]
            setAddress({ ...address, email: cartData["email"], "shipping_address": shipping, "billing_address": billing })

            setSameAddress(false)
        } else {
            cartData = res.data.data?.cart
        }
        cartData["taxObj"] = res.data.data?.taxObj
        setCart(cartData)
        // return cartData
    }

    function handleTypeChange(e) {
        setSameAddress(e.target.checked)
        if (e.target.checked) {
            let addressObj = {}
            _.each(address.shipping_address, (val, keys) => {
                return addressObj[keys] = val
            })
            setAddress({ ...address, 'billing_address': addressObj })
        } else {
            setAddress({ ...address, 'billing_address': addressInit("") })
        }
    }
    function toggleSummary() {
        let toggleSummary = !showSummary
        setshowSummary(toggleSummary)
    }

    function selectCountry(val) {
        setAddress({
            ...address, 'billing_address': {
                ...address.billing_address,
                'country': val
            }
        })
    }
    function handleChange(e, type, length) {
        if (e.target.value.length <= length) {
            if (e.target.name == "zip_code") {
                setAddress({
                    ...address,
                    [type]: {
                        ...address[type],
                        [e.target.name]: e.target.value,
                        "zip": e.target.value
                    }
                })
            } else {
                setAddress({
                    ...address,
                    [type]: {
                        ...address[type],
                        [e.target.name]: e.target.value
                    }
                })
            }
        }
        hanldeError(e.target.name, e.target.value, type == "shipping_address" ? "" : "bill_")
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let data = {
                email: address.email || "",
                first_name: address.shipping_address.first_name || "",
                last_name: address.shipping_address.last_name || "",
            }
            updateEmail(data)
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [address.email, address.shipping_address.first_name, address.shipping_address.last_name])

    const calculateTax = (state) => {
        let data = {
            "type": localStorage.getItem("cart_type") || "cart",
            "state": state
        }
        if (localStorage.getItem("cart_type") == "draft_order") {
            data = {
                ...data,
                shipping_address: {
                    ...address?.shipping_address,
                    zip: address?.shipping_address?.zip_code.toString()
                },
                billing_address: {
                    ...address?.billing_address,
                    zip: address?.billing_address?.zip_code.toString()
                },
                draft_order_id: localStorage.getItem("draft_order_id")
            }
            delete data["shipping_address"]["zip_code"]
            delete data["billing_address"]["zip_code"]
        }
        console.log("calculate data", data)
        Api.PostApi('/checkout/calculateTax', data).then(res => {
            console.log("calculateTax res", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data) {
                    let cartData = {}
                    if (data?.type == "draft_order") {
                        cartData = res.data.data?.draftOrder
                        cartData["cart_items"] = res.data.data?.draftOrder?.line_items
                        // let shipping = cartData["shipping_address"]
                        // shipping["zip_code"] = cartData["shipping_address"]["zip"]
                        // delete shipping["id"]
                        // delete shipping["created_at"]
                        // delete shipping["updated_at"]
                        // delete shipping["province_code"]
                        // let billing = cartData["billing_address"]
                        // billing["zip_code"] = cartData["billing_address"]["zip"]
                        // delete billing["id"]
                        // delete billing["created_at"]
                        // delete billing["updated_at"]
                        // delete billing["province_code"]
                        // setAddress({ ...address, email: cartData["email"], "shipping_address": shipping, "billing_address": billing })

                        setSameAddress(false)
                    } else {
                        cartData = res.data.data?.cart
                    }
                    cartData["taxObj"] = res.data.data?.taxObj

                    if (cartData?.total == "" || cartData?.total == "0" || cartData?.total == "0.00" || cartData?.total == 0) {
                        setshowGift(false)
                    } else {
                        setshowGift(true)
                    }
                    if (!cartData?.total) {
                        cartData["total"] = cartData["total_price"]
                    }

                    let arr = []
                    if (cartData) {
                        cartData.cart_items && cartData.cart_items.length > 0 && cartData.cart_items.map((opt, index) => {
                            arr.push({
                                item_id: opt?.cart_item_id,
                                index: index,
                                item_name: opt?.product_variant?.Product?.title || "",
                                affiliation: store && store?.store_name || "",
                                item_variant: opt?.product_variant?.title !== "Default Title" ? opt?.product_variant?.title : opt?.product_variant?.Product?.title || "",
                                price: opt?.product_variant?.price || "0",
                                quantity: opt?.quantity || 1
                            })
                        })

                        let data2 = {
                            currency: Api.CURRENCY_CODE,
                            value: cartData?.total || "0",
                            cart_id: cartData?.cart_id,
                            items: arr
                        }
                        console.log("begin_checkout", data2)
                        ReactGA.event("begin_checkout", data2);
                        ReactPixel.track("InitiateCheckout", data2);
                    }
                    console.log("cartData 1", cartData)
                    setCart(cartData)
                }
            }
        })
    }

    function handleSelectChange(e, type) {
        setAddress({
            ...address,
            [type]: {
                ...address[type],
                [e.target.name]: e.target.value
            }
        })
        hanldeError(e.target.name, e.target.value, type == "shipping_address" ? "" : "bill_")
    }

    useEffect(() => {
        if (address?.shipping_address?.province) {
            calculateTax(address?.shipping_address?.province)
        }
    }, [address?.shipping_address?.province])

    const [validEmail, setvalidEmail] = useState(false)
    useEffect(() => {
        if (address?.email && address?.shipping_address?.address1 && address?.shipping_address?.city && address?.shipping_address?.first_name && address?.shipping_address?.zip_code && (address?.shipping_address?.phone && address?.shipping_address?.phone !== "+91 " && (address?.shipping_address?.phone.includes("+91 ") && (address?.shipping_address?.phone.split("+91 ")[1].length > 9)))) {
            setShowStep2(true)
        } else {
            setShowStep2(false)
        }
    }, [address, validEmail])


    const handleNumberChange = (value, data, type) => {
        setAddress({
            ...address,
            [type]: {
                ...address[type],
                // phone: e.target.value
                'phone': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`
            }
        })
    };

    const updateEmail = (data) => {
        Api.PutApi('/checkout/update-email', data).then(res => {
            console.log("update-email res", res)
        })
    }

    function handleEmailChange(e) {
        setAddress({ ...address, "email": e.target.value })
        hanldeError(e.target.name, e.target.value, "")
    }
    function handleEmailBlur(e) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (e.target.value === "") {
            setError({ ...error, [e.target.name]: true })
        } else if (re.test(e.target.value) === false) {
            setError({ ...error, [e.target.name]: "Please enter correct email format" })
        } else {
            setvalidEmail(true)
            // updateEmail(e.target.value)
        }
    }

    function handleSubmitClick() {
        // let objData = {}
        // _.each(address, (val, keys) => {
        //     window.scrollTo({
        //         top: 0,
        //         behavior: 'smooth',
        //     });
        //     if (val === "") {
        //         return objData[keys] = true
        //     }
        // })
        // setError(objData)

        // if (address_type === "shipping") {
        //     let addressObj = {}
        //     _.each(address, (val, keys) => {
        //         return addressObj[keys] = val
        //     })
        //     setbillingAddress(addressObj)
        // }
        if (!address?.shipping_address?.first_name || address?.shipping_address?.first_name == "" || !address?.shipping_address?.phone || address?.shipping_address?.phone == "" || address?.shipping_address?.phone == "+91 " || (address?.shipping_address?.phone.includes("+91 ") && (address?.shipping_address?.phone.split("+91 ")[1].length > 0 && address?.shipping_address?.phone.split("+91 ")[1].length < 10)) || !address?.shipping_address?.address1 || address?.shipping_address?.address1 == "" || !address?.shipping_address?.city || address?.shipping_address?.city == "" || !address?.shipping_address?.zip_code || address?.shipping_address?.zip_code == "") {
            setshowError(true)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else if (!sameAddress && (!address?.billing_address?.first_name || address?.billing_address?.first_name == "" || !address?.billing_address?.phone || address?.billing_address?.phone == "" || address?.billing_address?.phone == "+91 " || (address?.billing_address?.phone.includes("+91 ") && (address?.billing_address?.phone.split("+91 ")[1].length > 0 && address?.billing_address?.phone.split("+91 ")[1].length < 10)) || !address?.billing_address?.address1 || address?.billing_address?.address1 == "" || !address?.billing_address?.city || address?.billing_address?.city == "" || !address?.billing_address?.zip_code || address?.billing_address?.zip_code == "")) {
            setshowError(true)
            window.scrollTo({
                top: 500,
                behavior: 'smooth',
            });
        } else if (_.isEmpty(activegateway)) {
            setshowError(true)
            window.scrollTo({
                top: 1000,
                behavior: 'smooth',
            });
        } else {
            setbtnLoading(true)
            delete address['shipping_address']["active"]
            delete address['shipping_address']["zip"]
            delete address['shipping_address']["id"]
            delete address['shipping_address']["country_code"]
            delete address['shipping_address']["createdAt"]
            delete address['shipping_address']["customer_id"]
            delete address['shipping_address']["updatedAt"]

            delete address['billing_address']["active"]
            delete address['billing_address']["zip"]
            delete address['billing_address']["id"]
            delete address['billing_address']["country_code"]
            delete address['billing_address']["createdAt"]
            delete address['billing_address']["customer_id"]
            delete address['billing_address']["updatedAt"]

            let data = {
                ...address,
                "billing_address": sameAddress ? address?.shipping_address : address?.billing_address

            }
            console.log("data: ", data)


            let arr = []
            if (cart) {
                cart.cart_items.length > 0 && cart.cart_items.map((opt, index) => {
                    arr.push({
                        item_id: opt?.cart_item_id,
                        index: index,
                        item_name: opt?.product_variant?.Product?.title || "",
                        affiliation: store && store?.store_name || "",
                        item_variant: opt?.product_variant?.title !== "Default Title" ? opt?.product_variant?.title : opt?.product_variant?.Product?.title || "",
                        price: opt?.product_variant?.price || "0",
                        discount: cart?.discount || 0,
                        coupon: cart?.discount_code?.title || "",
                        quantity: opt?.quantity || 1
                    })
                })

                let data2 = {
                    currency: Api.CURRENCY_CODE,
                    value: cart?.total || "0",
                    cart_id: cart?.cart_id,
                    coupon: cart?.discount_code?.title || "",
                    payment_type: activegateway?.payment_gateway?.gateway_name || "",
                    items: arr
                }
                console.log("add_payment_info", data2)
                ReactGA.event("add_payment_info", data2);
                ReactPixel.track("AddPaymentInfo", data2);
            }
            if (localStorage.getItem("cart_type") == "draft_order") {
                data = {
                    ...data,
                    draft_order_id: localStorage.getItem("draft_order_id")
                }
            }

            addAddressFn(data)
        }
    }

    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false)
        setbtnLoading(false)
    }

    const cashfreeOrder = (data) => {
        // setPaymentOverlay(true)
        Api.PostApi('/checkout/payment/cashfree', data).then(res => {
            console.log("res cashfree: ", res)
            if (res.error === true) {
                setbtnLoading(false)
                setPaymentOverlay(false)
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data) {
                    //// handleRazorpay(res.data.data)
                    // window.open(res.data.data?.short_url, "_self")
                    // setShow(true)

                    let resData = res.data.data

                    setTimeout(() => {
                        let checkoutOptions = {
                            paymentSessionId: resData?.payment_session_id,
                            redirectTarget: "_self",
                            mode: "sandbox",
                            // returnUrl: `http://localhost:3003?order_id=${resData?.order_id}`,
                            returnUrl: `https://pay.${Api.appURL}?order_id=${resData?.order_id}`,
                            // redirectTarget: "dropFrame"
                        }
                        cashfree.checkout(checkoutOptions)
                    }, 500);
                }
            }
        })
    }
    const cashfreeOrderCapture = (data) => {
        handleClose()
        setPaymentOverlay(true)
        Api.PostApi('/checkout/payment/cashfree-capture', data).then(res => {
            console.log("res cashfree capture: ", res)
            if (res.error === true) {
                setbtnLoading(false)
                setPaymentOverlay(false)
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data) {
                    //// handleRazorpay(res.data.data)
                    // window.open(res.data.data?.short_url, "_self")
                    let resData = res.data.data

                    if (resData) {
                        setbtnLoading(false)
                        setPaymentOverlay(false)
                        if (resData?.payment_status && resData?.payment_status.toLowerCase() == "success") {
                            let id = ""
                            let cart_type = localStorage.getItem("cart_type")
                            if (resData?.cart) {
                                id = resData?.cart?.cart_id
                            } else {
                                id = resData?.draft_order?.draft_order_id
                            }
                            setTimeout(() => {
                                removeStorage()
                                let domain2 = ""
                                if (resData?.store?.liveDomain) {
                                    domain2 = resData?.store?.liveDomain
                                } else {
                                    domain2 = resData?.store?.domain
                                }
                                window.open(`${domain2 && domain2.startsWith("http") ? domain2 : `https://${domain2}`}/thank-you?checkout_id=${id}&payment_method=cashfree&status=success&type=${cart_type}`, "_self")
                            }, 500);
                        }
                    }

                }
            }
        })
    }


    useEffect(() => {
        if (searchParams.get("order_id")) {
            let data = {
                order_id: searchParams.get("order_id"),
                type: localStorage.getItem("cart_type") || "cart",
            }
            if (localStorage.getItem("cart_type") == "draft_order" && localStorage.getItem("draft_order_id")) {
                data = {
                    ...data,
                    draft_order_id: localStorage.getItem("draft_order_id")
                }
            }
            cashfreeOrderCapture(data)
        }
    }, [searchParams.get("order_id")])


    const razorpayOrder = (data) => {
        setPaymentOverlay(true)
        Api.PostApi('/checkout/payment/razorpay', data).then(res => {
            console.log("res razorpay: ", res)
            if (res.error === true) {
                setbtnLoading(false)
                setPaymentOverlay(false)
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data) {
                    //// handleRazorpay(res.data.data)
                    // window.open(res.data.data?.short_url, "_self")
                    let resData = res.data.data
                    var options = {
                        "key": activegateway?.mode == "test" ? activegateway?.test_key : activegateway?.production_key,
                        "amount": resData?.amount,
                        "currency": resData?.currency || "INR",
                        "name": store?.store_name || store?.liveDomain || store?.domain,
                        "description": "",
                        "image": settings?.logo || "",
                        "order_id": resData?.id,
                        "handler": function (response) {
                            console.log(".razorpay_payment_id: ", response.razorpay_payment_id)
                            console.log(".razorpay_order_id: ", response.razorpay_order_id)
                            console.log(".razorpay_signature: ", response.razorpay_signature)
                            let body = {
                                'razorpay_payment_id': response.razorpay_payment_id,
                                'razorpay_order_id': response.razorpay_order_id,
                                'razorpay_signature': response.razorpay_signature
                            }
                            Api.PostApi('/checkout/razorpay/payment-verify', body).then(res => {
                                console.log("res razorpay: ", res)
                                if (res.error === true) {
                                    Api.Alert(res.response.data.error, "error")
                                    setbtnLoading(false)
                                    setPaymentOverlay(false)
                                } else {
                                    if (res.data.data && res.data.data?.signatureIsValid) {
                                        let body2 = {
                                            ...data,
                                            'payment_id': response.razorpay_payment_id
                                        }
                                        Api.PostApi('/checkout/payment/razorpay-capture', body2).then(resp => {
                                            console.log("res razorpay capture: ", resp)
                                            if (resp.error === true) {
                                                setbtnLoading(false)
                                                setPaymentOverlay(false)
                                                Api.Alert(resp.response.data.error, "error")
                                            } else {
                                                if (resp.data.data) {
                                                    setbtnLoading(false)
                                                    setPaymentOverlay(false)
                                                    if (resp.data.data?.status == "paid" || resp.data.data?.status == "authorized" || resp.data.data?.status == "success" || resp.data.data?.status == "captured") {
                                                        let id = ""
                                                        let cart_type = localStorage.getItem("cart_type")
                                                        if (resp.data.data?.cart) {
                                                            id = resp.data.data?.cart?.cart_id
                                                        } else {
                                                            id = resp.data.data?.draft_order?.draft_order_id
                                                        }
                                                        setTimeout(() => {
                                                            removeStorage()
                                                            let domain2 = ""
                                                            if (resp.data.data?.store?.liveDomain) {
                                                                domain2 = resp.data.data?.store?.liveDomain
                                                            } else {
                                                                domain2 = resp.data.data?.store?.domain
                                                            }
                                                            window.open(`${domain2 && domain2.startsWith("http") ? domain2 : `https://${domain2}`}/thank-you?checkout_id=${id}&payment_method=razorpay&status=success&type=${cart_type}`, "_self")
                                                        }, 500);
                                                    }
                                                }
                                            }
                                        })
                                    }
                                }
                            })
                        },
                        "prefill": {
                            "name": address?.shipping_address?.first_name && `${address?.shipping_address?.first_name} ${address?.shipping_address?.last_name || ""}` || "",
                            "email": address?.email || "",
                            "contact": address?.shipping_address?.phone || ""
                        },
                        "notes": {
                            "address": ""
                        },
                        // "theme": {
                        //     "color": "#f1592a"
                        // },
                        "modal": {
                            "ondismiss": function () {
                                console.log('Checkout form closed');
                                setbtnLoading(false)
                                setPaymentOverlay(false)
                            }
                        }
                    };

                    console.log("data options: ", options)
                    var paymentObject = new window.Razorpay(options);
                    console.log("data paymentObject: ", paymentObject)
                    paymentObject.open()


                }
            }
        })
    }
    const stripeOrder = async (data) => {
        // Api.PostApi('/checkout/stripe', data).then(res => {
        //     setbtnLoading(false)
        //     console.log("res stripe: ", res)
        //     if (res.error === true) {
        //         Api.Alert(res.response.data.error, "error")
        //     } else {
        //         if (res.data.data) {
        // window.open(res.data.data?.url, "_self")

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const card = elements.getElement(CardNumberElement);

        if (card == null) {
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card,
        });


        if (payload.error) {
            console.log('[error]', payload.error);
            setbtnLoading(false)
            Api.Alert(payload.error?.message, "error")
            setPaymentOverlay(false)

            // setErrorMessage(payload.error.message);
            // setPaymentMethod(null);
        } else {
            stripe.createToken(card).then(function (result) {
                setPaymentOverlay(true)
                console.log("result: ", result)
                console.log("payload: ", payload)
                let body = {
                    ...data,
                    card_token: result?.token?.id,
                    paymentMethod_id: payload.paymentMethod?.id
                }
                Api.PostApi("/checkout/stripe", body).then(resp => {
                    console.log("res stripe: ", resp)
                    if (resp.error === true) {
                        setbtnLoading(false)
                        setPaymentOverlay(false)
                        if (resp.res.data?.errorCode == "STRIPE_AUTH_FAILED") {
                            getGatewaysList()
                        }
                        Api.Alert(resp.response.data.error, "error")
                    } else {
                        if (resp?.data.data?.client_secret) {
                            stripe.confirmCardPayment(resp?.data.data?.client_secret, {
                                payment_method: {
                                    card: card,
                                    billing_details: {
                                        name: address?.shipping_address?.first_name ? `${address?.shipping_address?.first_name} ${address?.shipping_address?.last_name || ""}` : "",
                                    },
                                },
                            }).then(function (result2) {
                                if (result2.error) {
                                    setbtnLoading(false)
                                    setPaymentOverlay(false)
                                    console.log("action error: ", result2.error);
                                    if (result2.error?.message) {
                                        Api.Alert(result2.error?.message || "", "error")
                                    }
                                    /* Handle error */
                                } else {
                                    console.log("action success: ", result2);
                                    if (result2?.paymentIntent?.id) {
                                        let body2 = {
                                            ...data,
                                            payment_intent: result2?.paymentIntent?.id
                                        }
                                        Api.PostApi("/checkout/stripe/capture", body2).then(res => {
                                            console.log("res stripe capture: ", res)
                                            if (res.error === true) {
                                                setbtnLoading(false)
                                                setPaymentOverlay(false)
                                                Api.Alert(res.response.data.error, "error")
                                            } else {
                                                if (res.data.data?.status == "succeeded" || res.data.data?.status == "paid" || res.data.data?.status == "authorized" || res.data.data?.status == "success") {
                                                    let id = ""
                                                    let cart_type = localStorage.getItem("cart_type")
                                                    if (res.data.data?.cart) {
                                                        id = res.data.data?.cart?.cart_id
                                                    } else {
                                                        id = res.data.data?.draft_order?.draft_order_id
                                                    }

                                                    setbtnLoading(false)
                                                    removeStorage()
                                                    let domain2 = ""
                                                    if (res.data.data?.store?.liveDomain) {
                                                        domain2 = res.data.data?.store?.liveDomain
                                                    } else {
                                                        domain2 = res.data.data?.store?.domain
                                                    }
                                                    window.open(`${domain2 && domain2.startsWith("http") ? domain2 : `https://${domain2}`}/thank-you?checkout_id=${id}&payment_method=stripe&status=success&type=${cart_type}`, "_self")
                                                    setPaymentOverlay(false)
                                                } else {
                                                    setbtnLoading(false)
                                                    setPaymentOverlay(false)
                                                }
                                            }
                                        })
                                    } else {
                                        setbtnLoading(false)
                                        setPaymentOverlay(false)
                                    }
                                }
                            })
                        } else {
                            setbtnLoading(false)
                            setPaymentOverlay(false)
                        }
                    }
                })
            })
        }

    }

    const [paytmData, setpaytmData] = useState({})
    const paytmOrder = (data) => {
        Api.PostApi('/checkout/paytm', data).then(async res => {
            setbtnLoading(false)
            console.log("res paytm: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data) {
                    setpaytmData(res.data.data)
                    // makePaytmPayment(res.data.data)
                }
            }
        })
    }
    const codOrder = (data) => {
        setPaymentOverlay(true)
        Api.PostApi('/checkout/cod', data).then(res => {
            setbtnLoading(false)
            console.log("res cod: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setPaymentOverlay(false)
            } else {
                if (res.data.data) {
                    getStore(`COD##${res.data.data?.cart_id || localStorage.getItem("draft_order_id")}`, localStorage.getItem("cart_type"))
                    setPaymentOverlay(false)
                    // window.open(`https://securegw-stage.paytm.in/theia/api/v1/showPaymentPage?mid=${res.data.data?.MID}&orderId=${res.data.data?.ORDER_ID}`, "_self")
                } else {
                    setPaymentOverlay(false)
                }
            }
        })
    }
    const zeroOrder = (data) => {
        setPaymentOverlay(true)
        Api.PostApi('/checkout/payment/order', data).then(res => {
            setbtnLoading(false)
            console.log("res cod: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setPaymentOverlay(false)
            } else {
                if (res.data.data) {
                    setPaymentOverlay(false)
                    getStore(`COD##${res.data.data?.cart_id || localStorage.getItem("draft_order_id")}`, localStorage.getItem("cart_type"))
                    // window.open(`https://securegw-stage.paytm.in/theia/api/v1/showPaymentPage?mid=${res.data.data?.MID}&orderId=${res.data.data?.ORDER_ID}`, "_self")
                } else {
                    setPaymentOverlay(false)
                }
            }
        })
    }

    const stripeOrderCapture = (data, cart_type) => {
        document.body.classList.add("page_loading")
        Api.PostApi('/checkout/stripe/capture', data).then(res => {
            console.log("res stripe: ", res)
            setTimeout(() => {
                document.body.classList.remove("page_loading")
            }, 2000);
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                getStore("failed", cart_type)
            } else {
                if (res.data.data) {
                    let type = "success"
                    if (res.data.data?.status == "complete" && res.data.data?.payment_status == "paid") {
                        type = "success"
                    } else {
                        type = "failed"
                    }
                    if (res.data.data?.metadata?.cart_id || res.data.data?.metadata?.draft_order_id) {
                        getStore(type, cart_type)
                        // window.open(`https://${store?.domain}/thank-you?checkout_id=${res.data.data?.metadata?.cart_id}&payment_method=stripe&status=${type}`, "_self")
                    }
                }
            }
        })
    }
    const razorpayOrderCapture = (data, cart_type) => {
        document.body.classList.add("page_loading")
        Api.PostApi('/checkout/payment/razorpay-capture', data).then(res => {
            console.log("res razorpay: ", res)
            setTimeout(() => {
                document.body.classList.remove("page_loading")
            }, 2000);
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                getStore("failed", cart_type)
            } else {
                if (res.data.data) {
                    let type = "success"
                    if (res.data.data?.status == "paid") {
                        type = "success"
                    } else {
                        type = "failed"
                    }
                    if (res.data.data?.notes?.cart_id || res.data.data?.notes?.draft_order_id) {
                        getStore(type, cart_type)
                        // window.open(`https://${store?.domain}/thank-you?checkout_id=${res.data.data?.metadata?.cart_id}&payment_method=stripe&status=${type}`, "_self")
                    }
                }
            }
        })
    }


    const [cart, setCart] = useState({})
    const [gateways, setgateways] = useState([])
    const [discountList, setDiscountList] = useState({})
    const getGatewaysList = () => {
        Api.GetApi(`/checkout/storeGateways`).then(resp => {
            console.log("res gateway: ", resp)
            if (resp.error === true) {

                if (resp.res.data?.errorCode !== "INVALID_TOKEN") {
                    Api.Alert(resp.response.data.error, "error")
                }
            } else {
                if (resp.data.data) {
                    let gatewayList = resp.data.data.filter((x) => x.is_active && x?.payment_gateway?.gateway_name !== "PayTm")
                    setgateways(gatewayList)
                    setactivegateway(gatewayList[0])
                }
            }
        })
    }
    const getCartFn = () => {
        let data = {}
        if (localStorage.getItem("cart_type") == "draft_order" && localStorage.getItem("draft_order_id")) {
            data = {
                // token: localStorage.getItem("cart_token"),
                // store_id: localStorage.getItem("store_id"),
                type: localStorage.getItem("cart_type"),
                draft_order_id: localStorage.getItem("draft_order_id")
            }
        } else {
            data = {
                // token: localStorage.getItem("cart_token"),
                // store_id: localStorage.getItem("store_id"),
                type: "cart",
            }
        }
        Api.PostApi('/checkout', data).then(async res => {
            console.log("res cart: ", res)
            if (res.error === true) {
                setTimeout(() => {
                    setloading(false)
                }, 1000);
                if (res.res.data?.errorCode !== "INVALID_TOKEN") {
                    Api.Alert(res.response.data.error, "error")
                }
            } else {
                if (res.data.data) {
                    let cartData = {}
                    if (data?.type == "draft_order") {
                        cartData = res.data.data?.draftOrder
                        cartData["cart_items"] = res.data.data?.draftOrder?.line_items
                        let shipping = cartData["shipping_address"]
                        shipping["zip_code"] = cartData["shipping_address"]["zip"]
                        delete shipping["id"]
                        delete shipping["created_at"]
                        delete shipping["updated_at"]
                        delete shipping["province_code"]
                        let billing = cartData["billing_address"]
                        billing["zip_code"] = cartData["billing_address"]["zip"]
                        delete billing["id"]
                        delete billing["created_at"]
                        delete billing["updated_at"]
                        delete billing["province_code"]
                        setAddress({ ...address, email: cartData["email"], "shipping_address": shipping, "billing_address": billing })

                        setSameAddress(false)
                    } else {
                        cartData = res.data.data?.cart
                    }
                    if (cartData.cart_items.filter((x) => x?.is_gift_card).length > 0) {
                        setIsGiftCard(true)
                    } else {
                        setIsGiftCard(false)
                    }
                    if (res.data.data?.taxObj) {
                        cartData["taxObj"] = res.data.data?.taxObj
                    }


                    // let cartData = {}
                    // if (data?.type == "draft_order") {
                    //     cartData = res.data.data?.draftOrder
                    // } else {
                    //     cartData = res.data.data?.cart
                    // }
                    if (cartData) {
                        if (cartData?.total == "" || cartData?.total == "0" || cartData?.total == "0.00" || cartData?.total == 0) {
                            setshowGift(false)
                        } else {
                            setshowGift(true)
                        }
                        if (!cartData?.total) {
                            cartData["total"] = cartData["total_price"]
                        }
                        setCart(cartData)
                        setTimeout(() => {
                            if (address?.shipping_address?.province) {
                                calculateTax(address?.shipping_address?.province)
                            }
                        }, 1000);


                        let arr = []
                        if (cartData) {
                            cartData.cart_items.length > 0 && cartData.cart_items.map((opt, index) => {
                                arr.push({
                                    item_id: opt?.cart_item_id,
                                    index: index,
                                    item_name: opt?.product_variant?.Product?.title || "",
                                    affiliation: store && store?.store_name || "",
                                    item_variant: opt?.product_variant?.title !== "Default Title" ? opt?.product_variant?.title : opt?.product_variant?.Product?.title || "",
                                    price: opt?.product_variant?.price || "0",
                                    quantity: opt?.quantity || 1
                                })
                            })

                            let data2 = {
                                currency: Api.CURRENCY_CODE,
                                value: cartData?.total || "0",
                                cart_id: cartData?.cart_id,
                                items: arr
                            }
                            console.log("begin_checkout", data2)
                            ReactGA.event("begin_checkout", data2);
                            ReactPixel.track("InitiateCheckout", data2);
                        }


                        // if (cartData && cartData?.discount_code) {
                        //     setEnterCode(cartData?.discount_code?.title)
                        // } else if (cartData && cartData.cart_items.filter((x) => x?.discount_code_id && x?.discount_code).length > 0) {
                        //     setEnterCode(cartData.cart_items.filter((x) => x?.discount_code_id && x?.discount_code)[0]?.discount_code?.title)
                        // }

                        console.log("checkout data: ", data)
                        getGatewaysList()
                        Api.GetApi(`/checkout/discount-code`).then(resp2 => {
                            console.log("res discount: ", resp2)
                            if (resp2.error === true) {

                                if (resp2.res.data?.errorCode !== "INVALID_TOKEN") {
                                    Api.Alert(resp2.response.data.error, "error")
                                }
                            } else {
                                if (resp2.data.data) {
                                    setDiscountList(resp2.data.data)
                                }
                            }
                        })
                        setTimeout(() => {
                            setloading(false)
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            setloading(false)
                        }, 1000);
                    }
                }
            }
        })
    }
    const [user, setUser] = useState({})
    const getUserDetail = () => {
        Api.GetApi(`/checkout`).then(resp => {
            console.log("res user: ", resp)
            if (resp.error === true) {
                // Api.Alert(resp.response.data.error, "error")
                if (resp.res.data.error == "Customer does not exist") {
                    console.log("yesssssssssss")
                    logout()
                }
            } else {
                if (resp.data.data) {
                    setUser(resp.data.data)
                    setAddress({ ...address, 'email': resp.data.data?.email })
                }
            }
        })
    }
    console.log("user: ", user)
    console.log("discountList: ", discountList)
    const addAddressFn = (data) => {
        Api.PostApi('/checkoutAddress', data).then(res => {
            console.log("res checkout: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setbtnLoading(false)
            } else {
                if (res.data.data) {
                    let data = {}
                    let coddata = {}
                    if (!_.isEmpty(activegateway)) {
                        let bodyData = { type: localStorage.getItem("cart_type") }
                        // let bodyData = { store_id: address?.store_id, token: address?.token, type: localStorage.getItem("cart_type") }
                        if (localStorage.getItem("cart_type") == "draft_order") {
                            data = {
                                ...bodyData,
                                draft_order_id: localStorage.getItem("draft_order_id")
                            }
                        } else {
                            data = { ...bodyData }
                        }
                        if (!cart?.total || cart?.total == "" || cart?.total == "0" || cart?.total == "0.00" || cart?.total == 0) {
                            console.log("payment data: ", data)
                            if (localStorage.getItem("cart_type") == "cart") {
                                coddata = { type: localStorage.getItem("cart_type") }
                                // coddata = { store_id: address?.store_id, token: address?.token, type: localStorage.getItem("cart_type") }
                            } else {
                                coddata = { draft_order_id: localStorage.getItem("draft_order_id"), type: localStorage.getItem("cart_type") }
                                // coddata = { store_id: address?.store_id, draft_order_id: localStorage.getItem("draft_order_id"), type: localStorage.getItem("cart_type") }
                            }
                            zeroOrder(coddata)
                        } else {
                            if (activegateway?.payment_gateway?.gateway_name == "Cashfree") {
                                cashfreeOrder(data)
                            }
                            if (activegateway?.payment_gateway?.gateway_name == "RazorPay") {
                                razorpayOrder(data)
                            }
                            if (activegateway?.payment_gateway?.gateway_name == "PayUMoney") {
                                payu(data)
                            }
                            if (activegateway?.payment_gateway?.gateway_name == "Stripe") {
                                stripeOrder(data)
                            }
                            if (activegateway?.payment_gateway?.gateway_name == "PayTm") {
                                // paytmOrder(data)
                                makePaytmPayment(paytmData)
                            }
                            if (activegateway?.payment_gateway?.gateway_name == "COD") {
                                if (localStorage.getItem("cart_type") == "cart") {
                                    coddata = { type: localStorage.getItem("cart_type") }
                                    // coddata = { store_id: address?.store_id, token: address?.token, type: localStorage.getItem("cart_type") }
                                } else {
                                    coddata = { draft_order_id: localStorage.getItem("draft_order_id"), type: localStorage.getItem("cart_type") }
                                    // coddata = { store_id: address?.store_id, draft_order_id: localStorage.getItem("draft_order_id"), type: localStorage.getItem("cart_type") }
                                }
                                codOrder(coddata)
                            }
                        }
                    }
                }
            }
        })
    }

    const removeStorage = () => {
        localStorage.removeItem("cart_token")
        localStorage.removeItem("auth_type")
        localStorage.removeItem("cart_type")
        localStorage.removeItem("draft_order_id")
        localStorage.removeItem("address")
        localStorage.removeItem("store_id")
        localStorage.removeItem("ga")
        localStorage.removeItem("fbp")
    }

    const logout = () => {
        localStorage["auth_type"] = "GUEST"
        setUser({})
        setAddress({ ...address, "email": "" })
        getStore("", localStorage.getItem("cart_type"))
    }
    const [settings, setSettings] = useState({})
    const getCheckoutSettings = () => {
        Api.GetApi(`/checkout-setting`).then(resp => {
            if (resp.error === true) {

                if (resp.res.data?.errorCode !== "INVALID_TOKEN") {
                    Api.Alert(resp.response.data.error, "error")
                }
            } else {
                if (resp.data.data) {
                    setSettings(resp.data.data)
                }
            }
        })
    }

    const [store, setStore] = useState({})
    const [loginUrl, setloginUrl] = useState("")
    const getStore = (type, cart_type) => {
        Api.GetApi(`/checkout/store`).then(resp => {
            console.log("res gateway: ", resp)
            console.log("res gateway type: ", type)
            if (resp.error === true) {

                if (resp.res.data?.errorCode !== "INVALID_TOKEN") {
                    Api.Alert(resp.response.data.error, "error")
                }
            } else {
                if (resp.data.data) {
                    let domain2 = ""
                    if (resp.data.data?.liveDomain) {
                        domain2 = resp.data.data?.liveDomain
                    } else {
                        domain2 = resp.data.data?.domain
                    }
                    setloginUrl(domain2 && domain2.startsWith("http") ? domain2 : `https://${domain2}`)
                    setStore(resp.data.data)
                    // if (searchParams.get("orderPaymentId")) {
                    //     removeStorage()
                    //     setTimeout(() => {
                    //         window.open(`https://${resp.data.data?.domain}/thank-you?checkout_id=${searchParams.get("cart_id") || localStorage.getItem("draft_order_id")}&payment_method=stripe&status=${type}&type=${cart_type}`, "_self")
                    //     }, 1000);
                    // }
                    // if (searchParams.get("razorpay_payment_link_id") && searchParams.get("razorpay_payment_link_reference_id")) {
                    //     removeStorage()
                    //     setTimeout(() => {
                    //         window.open(`https://${resp.data.data?.domain}/thank-you?checkout_id=${searchParams.get("razorpay_payment_link_reference_id")}&payment_method=razorpay&status=${type}&type=${cart_type}`, "_self")
                    //     }, 1000);
                    // }
                    if (type.split("##")[0] == "COD") {
                        removeStorage()
                        setTimeout(() => {
                            let domain2 = ""
                            if (resp.data.data?.liveDomain) {
                                domain2 = resp.data.data?.liveDomain
                            } else {
                                domain2 = resp.data.data?.domain
                            }
                            window.open(`${domain2 && domain2.startsWith("http") ? domain2 : `https://${domain2}`}/thank-you?checkout_id=${type.split("##")[1]}&status=success&type=${cart_type}`, "_self")
                            // window.open(`https://${resp.data.data?.domain}/thank-you?checkout_id=${type.split("##")[1]}&payment_method=cod&status=success&type=${cart_type}`, "_self")
                        }, 1000);
                    }
                }
            }
        })
    }

    const navigate = useNavigate()
    useEffect(() => {
        searchParams.delete("store_id")
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        if (searchParams.get("token") && searchParams.get("type")) {
            localStorage["cart_token"] = searchParams.get("token")
            localStorage["cart_type"] = searchParams.get("type")
            if (searchParams.get("draft_order_id")) {
                localStorage["draft_order_id"] = searchParams.get("draft_order_id")
            }
            searchParams.delete("token")
            searchParams.delete("type")
            searchParams.delete("draft_order_id")
            const to = { pathname: location.pathname, search: searchParams.toString() };
            navigate(to, { replace: true });
        }

        if (searchParams.get("auth_type")) {
            localStorage['auth_type'] = searchParams.get("auth_type");
            searchParams.delete("auth_type")
            const to = { pathname: location.pathname, search: searchParams.toString() };
            navigate(to, { replace: true });
        } else {
            // logout()
        }
        if (searchParams.get("logout") && searchParams.get("logout") == 1) {
            searchParams.delete("logout")
            const to = { pathname: location.pathname, search: searchParams.toString() };
            navigate(to, { replace: true });
            logout()
        }

        if (searchParams.get("ga")) {
            if (searchParams.get("ga") !== "NA") {
                localStorage['ga'] = searchParams.get("ga");
                ReactGA.initialize(searchParams.get("ga"));
            }
            searchParams.delete("ga")
            const to = { pathname: location.pathname, search: searchParams.toString() };
            navigate(to, { replace: true });
        } else {
            if (localStorage.getItem("ga") && localStorage.getItem("ga") !== "NA") {
                ReactGA.initialize(localStorage.getItem("ga"));
            }
        }
        if (searchParams.get("fbp")) {
            if (searchParams.get("fbp") !== "NA") {
                localStorage['fbp'] = searchParams.get("fbp");
                ReactPixel.init(searchParams.get("fbp"));
            }
            searchParams.delete("fbp")
            const to = { pathname: location.pathname, search: searchParams.toString() };
            navigate(to, { replace: true });
        } else {
            if (localStorage.getItem("fbp") && localStorage.getItem("fbp") !== "NA") {
                ReactPixel.init(localStorage.getItem("fbp"));
            }
        }

        setTimeout(() => {
            if (localStorage.getItem("auth_type") == "USER") {
                getUserDetail()
            } else {
                // logout()
            }
        }, 500);
        getStore("", localStorage.getItem("cart_type"))
        getCheckoutSettings()
        getCartFn()
        let data = {}
        let bodyData = { type: localStorage.getItem("cart_type") }
        // let bodyData = { store_id: localStorage.getItem("store_id"), token: localStorage.getItem("cart_token"), type: localStorage.getItem("cart_type") }
        if (localStorage.getItem("cart_type") == "draft_order") {
            data = {
                ...bodyData,
                draft_order_id: localStorage.getItem("draft_order_id")
            }
        } else {
            data = { ...bodyData }
        }
        setAddress({ ...address, type: localStorage.getItem("cart_type") })
        // setAddress({ ...address, token: localStorage.getItem("cart_token"), store_id: localStorage.getItem("store_id"), type: localStorage.getItem("cart_type") })
    }, [])


    useEffect(() => {
        if (searchParams.get("orderPaymentId")) {
            let data = {
                "type": localStorage.getItem("cart_type"),
                "orderPaymentId": searchParams.get("orderPaymentId")
            }
            stripeOrderCapture(data, localStorage.getItem("cart_type"))
        }
        if (searchParams.get("razorpay_payment_link_id")) {
            let body = {}
            let data = {
                "type": localStorage.getItem("cart_type"),
                "paymentLinkId": searchParams.get("razorpay_payment_link_id")
            }
            if (localStorage.getItem("cart_type") == "draft_order") {
                body = {
                    ...data,
                    "draft_order_id": localStorage.getItem("draft_order_id"),
                }
            } else {
                body = {
                    ...data,
                }
            }
            razorpayOrderCapture(body, localStorage.getItem("cart_type"))
        }
    }, [])

    const [showDropdown, setshowDropdown] = useState(false)
    const chooseAdress = (data, i) => {
        let arr = []
        user.addresses.map((opt, index) => {
            if (i == index) {
                arr.push({ ...opt, 'active': true })
            } else {
                arr.push({ ...opt })
            }
        })
        let resData = { ...data }
        resData["zip_code"] = data?.zip
        // resData["phone"] = data?.phone && data?.phone.includes("+91 ") ? data?.phone.split("+91 ")[1] && data?.phone.split("+91 ")[1] !== "" && data?.phone.split("+91 ")[1].split(" ").join("") : `+91 ${data?.phone}`
        resData["phone"] = data?.phone && !data?.phone.includes("+91 ") ? data?.phone.split("+91 ")[1] && data?.phone.split("+91 ")[1] !== "" && data?.phone.split("+91 ")[1].split(" ").join("") : !data?.phone ? `+91 ${data?.phone}` : data?.phone
        delete resData["name"]
        delete resData["zip"]
        delete resData["is_default"]
        setAddress({ ...address, shipping_address: resData })
        setUser({ ...user, addresses: arr })
        setshowDropdown(false)
    }

    const showDiscountBar = () => {
        setshowDiscount(true)
    }
    const applyDiscount = (data) => {
        Api.PostApi("/checkout/apply-discount-code", data).then(resp => {
            console.log("res apply discount: ", resp)
            if (resp.error === true) {
                Api.Alert(resp.response.data.error, "error")
                let title = ""
                if (cart && cart?.discount_code) {
                    title = cart?.discount_code?.title
                } else if (cart && cart.cart_items.filter((x) => x?.discount_code_id && x?.discount_code).length > 0) {
                    title = cart.cart_items.filter((x) => x?.discount_code_id && x?.discount_code)[0]?.discount_code?.title
                } else {
                    title = enterCode
                }
                if (title) {
                    removeDiscount(title)
                }
            } else {
                if (resp.data.data) {
                    setshowDiscount(false)
                    getCartFn()
                    setEnterCode("")
                }
            }
        })
    }
    const removeDiscount = (title) => {
        let title2 = title
        if (cart && cart?.discount_code) {
            title2 = cart?.discount_code?.title
        } else if (cart && cart.cart_items.filter((x) => x?.discount_code_id && x?.discount_code).length > 0) {
            title2 = cart.cart_items.filter((x) => x?.discount_code_id && x?.discount_code)[0]?.discount_code?.title
        } else if (cart && cart.gift_card_id) {
            title2 = title
        } else {
            title2 = enterCode
        }
        let body = {
            type: localStorage.getItem("cart_type"),
            discount_code: title2,
        }
        if (title2 && title2 !== "") {
            Api.PostApi("/checkout/remove-discount-code", body).then(resp => {
                console.log("res remove discount: ", resp)
                if (resp.error === true) {
                    Api.Alert(resp.response.data.error, "error")
                } else {
                    if (resp.data.data) {
                        getCartFn()
                        setEnterCode("")
                    }
                }
            })
        }
    }


    const [enterCode, setEnterCode] = useState("")
    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (address?.email !== "") {
                console.log("address?.email:", address?.email);
                console.log("address?.enterCode:", enterCode);
                //     let data = { title: enterCode }
                //     // applyCode(data)
                //     removeDiscount(data)
                // }else{
                let title = ""
                if (cart && !_.isEmpty(cart) && cart?.discount_code) {
                    title = cart?.discount_code?.title
                } else if (cart && !_.isEmpty(cart) && cart.cart_items.filter((x) => x?.discount_code_id && x?.discount_code).length > 0) {
                    title = cart.cart_items.filter((x) => x?.discount_code_id && x?.discount_code)[0]?.discount_code?.title
                }
                if (title) {
                    let data = { title: title }
                    applyCode(data)
                }
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [address?.email]);

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            localStorage["address"] = JSON.stringify(address)
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [address]);

    const applyCode = (data) => {
        console.log("data: ", data)
        let body = {
            type: localStorage.getItem("cart_type"),
            discount_code: data?.title,
            email: address?.email || ""
        }
        let bodyData = {}
        if (localStorage.getItem("cart_type") == "draft_order") {
            bodyData = {
                ...body,
                draft_order_id: localStorage.getItem("draft_order_id")
            }
        } else {
            bodyData = {
                ...body
            }
        }
        applyDiscount(bodyData)
    }

    const applyDiscountCode = (e) => {
        e.preventDefault();
        let data = { title: enterCode }
        applyCode(data)
    }
    const handleDiscountInput = (e) => {
        setEnterCode(e.target.value)
    }
    console.log("enterCode: ", enterCode)
    console.log("store: ", store)

    const makePaytmPayment = (data) => {
        var config = {
            "root": "",
            "style": {
                "bodyBackgroundColor": "#fafafb",
                "bodyColor": "",
                "themeBackgroundColor": "#f1592a",
                "themeColor": "#ffffff",
                "headerBackgroundColor": "#284055",
                "headerColor": "#ffffff",
                "errorColor": "",
                "successColor": "",
                "card": {
                    "padding": "",
                    "backgroundColor": ""
                }
            },
            "data": {
                "orderId": data?.orderId,
                "token": data?.txnToken,
                "tokenType": "TXN_TOKEN",
                "amount": data?.amount / 100
            },
            "payMode": {
                "labels": {},
                "filter": {
                    "exclude": []
                },
                "order": [
                    "CC",
                    "DC",
                    "NB",
                    "UPI",
                    "PPBL",
                    "PPI",
                    "BALANCE"
                ]
            },
            "website": "WEBSTAGING",
            "flow": "DEFAULT",
            "merchant": {
                "mid": data?.mid || activegateway?.mode == "test" ? activegateway?.test_mid : activegateway?.production_mid,
                // "mid": "AYhZPv81702900565837",
                "redirect": false
            },
            "handler": {
                "transactionStatus":
                    function transactionStatus(paymentStatus) {
                        console.log("paymentStatus: ", paymentStatus);
                    },
                "notifyMerchant":
                    function notifyMerchant(eventName, data) {
                        console.log("Closed");
                    }
            }
        };

        console.log("config: ", config)

        if (window.Paytm && window.Paytm.CheckoutJS) {
            window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
                window.Paytm.CheckoutJS.init(config).
                    then(function onSuccess() {
                        window.Paytm.CheckoutJS.invoke();
                    }).catch(function onError(error) {
                        console.log("Error => ", error);
                    });
            })
        }
    }

    const payu = (data) => {
        console.log("res payumoney data: ", data)
        Api.PostApi('/checkout/payment/payumoney', data).then(res => {
            console.log("res payumoney: ", res)
            if (res.error === true) {
                setbtnLoading(false)
                setPaymentOverlay(false)
                Api.Alert(res.response.data.error, "error")
            } else {
                setbtnLoading(true)
                let resdata = { ...res.data.data }
                resdata = {
                    ...resdata,
                    productinfo: resdata?.productinfo.toString()
                }
                redirectToPayU(resdata)
            }
        })
    }

    const redirectToPayU = (pd) => {
        var handlers = {
            responseHandler: function (BOLT) {
                console.log("BOLT: ", BOLT);
                if (BOLT?.response?.txnStatus == "CANCEL") {
                    document.body.style.overflow = "visible"
                    setbtnLoading(false)
                    setPaymentOverlay(false)
                } else {
                    let data = {
                        "type": localStorage.getItem("cart_type"),
                        "txnId": BOLT?.response?.txnid.toString()
                    }
                    if (localStorage.getItem("cart_type") == "draft_order" && localStorage.getItem("draft_order_id")) {
                        data = {
                            ...data,
                            draft_order_id: localStorage.getItem("draft_order_id")
                        }
                    }
                    setbtnLoading(true)
                    setPaymentOverlay(true)
                    Api.PostApi('/checkout/payment/payumoney-check-payment', data).then(res => {
                        console.log("res payumoney-check-payment: ", res)
                        if (res.error === true) {
                            setbtnLoading(false)
                            setPaymentOverlay(false)
                            Api.Alert(res.response.data.error, "error")
                        } else {
                            let id = ""
                            let cart_type = localStorage.getItem("cart_type")
                            if (res.data.data?.cart) {
                                id = res.data.data?.cart?.cart_id
                            } else {
                                id = res.data.data?.draft_order?.draft_order_id
                            }
                            setTimeout(() => {
                                removeStorage()
                                let domain2 = ""
                                if (res.data.data?.store?.liveDomain) {
                                    domain2 = res.data.data?.store?.liveDomain
                                } else {
                                    domain2 = res.data.data?.store?.domain
                                }
                                window.open(`${domain2 && domain2.startsWith("http") ? domain2 : `https://${domain2}`}/thank-you?checkout_id=${id}&payment_method=payumoney&status=success&type=${cart_type}`, "_self")
                                setbtnLoading(false)
                                setPaymentOverlay(false)
                            }, 500);
                        }
                    })
                }
            },
            catchException: function (BOLT) {
                console.log("BOLT err: ", BOLT);
                setbtnLoading(false)
                setPaymentOverlay(false)
                console.log('Payment failed. Please try again.');
            }
        }
        let boltFrame = document.getElementById("boltFrame")
        if (boltFrame) {
            setTimeout(() => {
                boltFrame.style.display = "block"
            }, 1000);
        }
        bolt.launch(pd, handlers);
    }

    console.log("cart: ", cart)
    console.log("Address: ", address)
    console.log("error state: ", error)
    console.log("gateways: ", gateways)
    console.log("stripe: ", stripe)
    console.log("user: ", user)
    console.log("settings: ", settings)
    return (
        <div className="checkout_main_outer">
            {/* <div className="checkout_top_headline">
                <p>Free delivery for order $50+ US and $125+ worldwide</p>
            </div> */}
            <Helmet>
                <title>{store?.store_name ? `${store?.store_name || ""} | Checkout` : "Checkout"}</title>
                <link rel="icon" href={!_.isEmpty(settings) && settings?.favicon && settings?.favicon !== "" ? settings?.favicon : ""} />
            </Helmet>
            <div className="checkout_main_block">
                <div className="checkout_main_block_inner">
                    {loading ?
                        <div className='linear_progress'>
                            <div className='linear_progress_bar'></div>
                        </div>
                        :
                        !_.isEmpty(cart) ?
                            <div className="container">
                                <div className="checkout_row">
                                    <div className="checkout_col checkout_col_left">
                                        <div className="checkout_logo_block">
                                            {settings && settings?.logo ?
                                                <a href={loginUrl} className="checkout_logo_image">
                                                    <img src={settings && settings?.logo || ""} alt="" className="img-fluid" width={settings?.logo_width || 130} />
                                                </a>
                                                :
                                                <h4 style={{ marginBottom: "0" }}>
                                                    <a href={loginUrl}>{store?.store_name || ""}</a>
                                                </h4>
                                            }
                                            <div className="md_show">
                                                <button className={`cart_summary_mobile ${showSummary ? "active" : ""}`} onClick={toggleSummary}>
                                                    <img src={cartImg} alt="" width="20" />
                                                    <strong className="cart_summary_price"><CurrencyFormat value={!_.isEmpty(cart) ? parseFloat(cart?.total || cart?.total_price || 0).toFixed(2) : "0.00"} displayType={'text'} thousandSeparator={true} prefix={Api.CURRENCY} /></strong>
                                                    <img src={arrowDown} alt="" width="12" className="arrow" />
                                                </button>
                                            </div>
                                        </div>
                                        {showSummary &&
                                            <div className="md_show">
                                                <Summary
                                                    btnColor={prefersDarkMode ? "#fff" : styled.btnColor}
                                                    btnTextColor={prefersDarkMode ? "#000" : styled.btnTextColor}
                                                    showGift={showGift}
                                                    cart={cart}
                                                    showDiscountBar={showDiscountBar}
                                                    removeDiscount={removeDiscount}
                                                    applyDiscountCode={applyDiscountCode}
                                                    handleDiscountInput={handleDiscountInput}
                                                    enterCode={enterCode}
                                                    address={address}
                                                    validEmail={validEmail}
                                                    type={localStorage.getItem("cart_type")}
                                                />
                                            </div>
                                        }
                                        <div className="checkout_shipping_form">
                                            <div className="form_outer">
                                                <div className="w-100 d-flex justify-content-between">
                                                    <label>Contact information</label>
                                                    {_.isEmpty(user) ?
                                                        <p><small>{window.innerWidth < 575 ? "" : "Already have an account?"} <a href={`${loginUrl}/login?payment_url=${window.location.href}&user_type=${localStorage.getItem("auth_type")}`}>Log in</a></small></p>
                                                        :
                                                        localStorage.getItem("cart_type") == "cart" &&
                                                        <p style={{ color: "red" }}><small><button onClick={logout}>Logout</button></small></p>
                                                    }
                                                </div>
                                                <div className="form_control">
                                                    {/* <label className="form_label">Enter your email*</label> */}
                                                    {!_.isEmpty(user) && address?.email && address?.email !== "" ?
                                                        <input type="email" className="checkout_input" name="email" value={address?.email} placeholder="Enter your email*" />
                                                        :
                                                        <input type="email" className={`checkout_input ${error.email ? "error" : ""}`} name="email" value={address?.email} onChange={handleEmailChange} onBlur={handleEmailBlur} placeholder="Enter your email*" />
                                                    }
                                                    <p className="input_error"><small>{error.email}</small></p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="checkout_shipping_address">
                                                <div className="form_outer">
                                                    <div className="shipping_address_outer">
                                                        <label>Shipping address</label>
                                                        {!_.isEmpty(user) && user.addresses.length > 0 &&
                                                            <div className="address_dropdown">
                                                                <button className="address_dropdown_placeholder" onClick={() => setshowDropdown(!showDropdown)}>Use address</button>
                                                                {showDropdown &&
                                                                    <div className="address_dropdown_outer">
                                                                        {user.addresses.map((addr, i) => {
                                                                            return (
                                                                                <button key={i} onClick={() => chooseAdress(addr, i)} className={addr?.active ? "active" : ""}>
                                                                                    {addr?.name}
                                                                                    {addr?.name ? <br /> : null}
                                                                                    {addr?.address1 ? `${addr?.address1}, ` : ""} {addr?.address2 ? `${addr?.address2}, ` : ""}
                                                                                    {addr?.city}, {addr?.country}
                                                                                </button>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <Shipping
                                                        handleChange={handleChange}
                                                        error={error}
                                                        handleNumberChange={handleNumberChange}
                                                        addressType={"shipping_address"}
                                                        address={address}
                                                        showError={showError}
                                                        handleSelectChange={handleSelectChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="checkout_billing_address">
                                            <div className="form_outer">
                                                <label>Billing address</label>
                                                <div className="checkout_checkbox_outer">
                                                    <label className="checkout_checkbox checkout_checkbox">
                                                        <input type="checkbox" name="sameAddress" id="sameAddress" value="sameAddress" checked={sameAddress ? true : false} onChange={handleTypeChange} />
                                                        <span></span>
                                                        <div className="checkout_checkbox_text">Same as shipping address</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {!sameAddress &&
                                            <div className="">
                                                <Shipping
                                                    selectCountry={selectCountry}
                                                    handleChange={handleChange}
                                                    error={error}
                                                    handleNumberChange={handleNumberChange}
                                                    addressType={"billing_address"}
                                                    address={address}
                                                    showError={showError}
                                                    handleSelectChange={handleSelectChange}
                                                />
                                            </div>
                                        }


                                        {gateways.length > 0 ?
                                            <>
                                                {showStep2 && cart && !_.isEmpty(cart) &&
                                                    <>
                                                        <hr />
                                                        {!cart?.total || cart?.total == "" || cart?.total == "0" || cart?.total == "0.00" || cart?.total == 0 ?
                                                            <>
                                                                {gateways.length == 1 && gateways[0].payment_gateway.gateway_name == "COD" && isGiftCard ?
                                                                    <>
                                                                        <div className="checkout_btn_outer" title="">
                                                                            <button className={`checkout_btn disabled`} style={{ backgroundColor: prefersDarkMode ? "#fff" : styled.btnColor, color: prefersDarkMode ? "#000" : styled.btnTextColor }}>
                                                                                Complete Purchase
                                                                            </button>
                                                                            <p></p>
                                                                            <p>Your information is secure and encrypted <img src={lock} alt="" height="12" /></p>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <div className="checkout_btn_outer">
                                                                        <button className={`checkout_btn ${_.isEmpty(cart) || btnLoading ? "disabled" : ""}`} style={{ backgroundColor: prefersDarkMode ? "#fff" : styled.btnColor, color: prefersDarkMode ? "#000" : styled.btnTextColor }} onClick={!_.isEmpty(cart) && handleSubmitClick}>
                                                                            {btnLoading ? "Please wait..." : "Complete Purchase"}
                                                                        </button>
                                                                        <p></p>
                                                                        <p>Your information is secure and encrypted <img src={lock} alt="" height="12" /></p>
                                                                    </div>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <Payment
                                                                    gateways={gateways}
                                                                    activegateway={activegateway}
                                                                    handlePaymentChange={handlePaymentChange}
                                                                    showError={showError}
                                                                    stripeData={stripeData}
                                                                    handleCardChange={handleCardChange}
                                                                    cardData={cardData}
                                                                    isGiftCard={isGiftCard}
                                                                />
                                                                {gateways.length == 1 && gateways[0].payment_gateway.gateway_name == "COD" && isGiftCard ?
                                                                    <>
                                                                        <div className="checkout_btn_outer" title="">
                                                                            <button className={`checkout_btn disabled`} style={{ backgroundColor: prefersDarkMode ? "#fff" : styled.btnColor, color: prefersDarkMode ? "#000" : styled.btnTextColor }}>
                                                                                Complete Purchase
                                                                            </button>
                                                                            <p></p>
                                                                            <p>Your information is secure and encrypted <img src={lock} alt="" height="12" /></p>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <div className="checkout_btn_outer">
                                                                        <button className={`checkout_btn ${_.isEmpty(cart) || btnLoading || (activegateway?.payment_gateway?.gateway_name == "Stripe" && disableStripe) ? "disabled" : ""}`} style={{ backgroundColor: prefersDarkMode ? "#fff" : styled.btnColor, color: prefersDarkMode ? "#000" : styled.btnTextColor }} onClick={!_.isEmpty(cart) && handleSubmitClick}>
                                                                            {btnLoading ? "Please wait..." : "Complete purchase"}
                                                                        </button>
                                                                        <p></p>
                                                                        <p>Your information is secure and encrypted <img src={lock} alt="" height="12" /></p>
                                                                    </div>
                                                                }
                                                            </>
                                                        }

                                                        {settings && settings?.disclaimer ?
                                                            <div className="md_hide">
                                                                <div className="checkout_footer" dangerouslySetInnerHTML={{ __html: settings?.disclaimer }}>
                                                                </div>
                                                            </div>
                                                            : null
                                                        }

                                                    </>
                                                }
                                            </>
                                            :
                                            <Alert variant="warning">No payment gateway configured, go back to store or contact to store owner.</Alert>
                                        }
                                    </div>

                                    <div className="checkout_col checkout_col_right md_hide">
                                        <div className=" sticky">
                                            <Summary
                                                btnColor={prefersDarkMode ? "#fff" : styled.btnColor}
                                                btnTextColor={prefersDarkMode ? "#000" : styled.btnTextColor}
                                                showGift={showGift}
                                                cart={cart}
                                                showDiscountBar={showDiscountBar}
                                                removeDiscount={removeDiscount}
                                                applyDiscountCode={applyDiscountCode}
                                                handleDiscountInput={handleDiscountInput}
                                                enterCode={enterCode}
                                                address={address}
                                                validEmail={validEmail}
                                                type={localStorage.getItem("cart_type")}
                                            />
                                        </div>
                                        {/* <div className="checkout_feature_image">
                                <img src={featureImage} alt="" className="img-fluid" />
                            </div> */}
                                        {/* <div className="checkout_feature_testimonial">
                                <div className="client_img">
                                    <img src={client} alt="" className="img-fluid" />
                                </div>
                                <div className="client_msg">
                                    <p>"A fun, rich, hydrating essential beauty products packed with loads of vitamins, nutrients, and premium ingredients! Best products I have ever used in my life. I will be purchasing again in the future and telling all my friends to try Truly as well!"</p>
                                    <p><strong>- Alicia, Los Angeles CA</strong></p>
                                </div>
                            </div> */}
                                        {/* <div className="sm_show">
                                <Disclaimer />
                            </div> */}
                                    </div>

                                </div>

                            </div>
                            :
                            <div className="checkout_main_block">
                                <div className="checkout_main_block_inner pagenotFound_outer">
                                    <div className="pagenotFound">
                                        <img src={noPage} alt="" width={400} />
                                        <h1>Page Not Found</h1>
                                        <p>The store you are looking for is unavailable. Enter the correct URL.</p>
                                        {loginUrl ?
                                            <div style={{ paddingTop: "20px" }}>
                                                <a href={loginUrl} className="btn">Back to store</a>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                <div className="checkout_footer_outer">
                    <div className="">
                        {settings.footer_links && settings.footer_links.length > 0 &&
                            <div className="checkout_footer_links">
                                {!_.isEmpty(store) && settings.footer_links.filter((x) => x?.url !== "" && x?.title !== "").map((opt, i) => {
                                    let domain2 = ""
                                    if (store?.liveDomain) {
                                        domain2 = store?.liveDomain
                                    } else {
                                        domain2 = store?.domain
                                    }
                                    return (
                                        <a href={`${domain2 && domain2.startsWith("http") ? domain2 : `https://${domain2}`}${opt?.url}`} target="_blank">{opt?.title}</a>
                                    )
                                })}
                            </div>
                        }
                        <p>All rights reserved. © {new Date().getFullYear()}</p>
                    </div>
                </div>
            </div>
            {paymentOverlay ?
                <div className="paymentOverlay">
                    <h3>Please wait, While we are processing your order</h3>
                </div>
                : null
            }

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Body>
                    <div className="dropFrame">
                        <iframe name="dropFrame"></iframe>
                    </div>
                </Modal.Body>
            </Modal>

            {/* {showDiscount &&
                <div className="discount_sidebar">
                    <Discount
                        btnColor={prefersDarkMode ? "#fff":styled.btnColor}
                        btnTextColor={prefersDarkMode ? "#000":styled.btnTextColor}
                        discountList={discountList}
                        applyCode={applyCode}
                    />
                    <div onClick={() => setshowDiscount(false)} className="discount_sidebar_overlay"></div>
                </div>
            } */}
        </div >
    )
}

export { Checkout }